.scheduler {
    width: 100%;
    font-family: Arial, sans-serif;
    overflow: hidden;
}

.topNav {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    margin-bottom: 18px;
}

.timeGridContainer {
    border: 1px solid #dee2e6;
    border-bottom: 0;
    overflow: auto;
    border-radius: 5px;
}

.dateControls {
    display: flex;
    align-items: center;
    border: 1px solid #E3E3E3;
    padding: 0px 0px;
    border-radius: 5px;
}

.dateControls button {
    border: none;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    background-color: transparent;
    padding: 0;
}

.arrowLeftContainer {
    justify-content: center;
    align-items: center;
    display: flex;
}


.dateTextHeader {
    margin: 0px 12px;
    font-weight: 400;
    font-size: 12px;
}

.timeGrid {
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    min-width: 900px;
}

.corner {
    width: 100px;
    background: #ddd;
}

.timeLabels {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.hour {
    text-align: center;
    flex: 1;
    padding: 5px;
    border-right: 1px solid #ccc;
}

.body {
    display: flex;
    flex-direction: column;
    min-width: 900px;
}

.row {
    display: flex;
    align-items: center;
    position: relative;
    height: 70px;
    border-bottom: 1px solid #f5f0f0;
}

.roomLabel {
    width: 120px;
    text-align: center;
    background: #f8f9fa;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 2px;
    color: rgb(0, 0, 0);
    font-size: 12px;
}

.timeSlots {
    flex-grow: 1;
    position: relative;
    display: flex;
    margin: 0px 6px;
}

.emptySlot {
    position: absolute;
    height: 16px;
    top: -12px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding-top: 2px;
    border: "none";
    border-width: 0;
}

.timeSlot {
    position: relative;
    background-color: #f0f0f0;
    height: 20px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

/* Event Blocks */
.event {
    position: absolute;
    background: teal;
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    top: 10%;
}

/* Overlapping Event Stacking */
.event:nth-child(odd) {
    background: #ff7043;
}

.event:nth-child(even) {
    background: #42a5f5;
}

/* Stacking events dynamically */
.event[data-index="1"] {
    top: 30px;
}

.event[data-index="2"] {
    top: 60px;
}

.event[data-index="3"] {
    top: 90px;
}

.flex {
    display: flex;
    align-items: center;
}

.infoCircleContainerIcon {
    margin-top: 4px;
    margin-left: 6px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
}

@media only screen and (max-width: 466px) {
    .topNav {
        flex-direction: column;

    }
}

@media only screen and (max-width:315px) {
    .dateTextHeader {
        font-size: 10px;
        margin: 16px;
    }
}